// bootstrap separated modules instead of the whole bootstrap js library
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/carousel';

// Magnific popup
import 'components/MagnificPopup/index';

// Slick
import '../node_modules/slick-carousel/slick/slick.js';
import 'components/Slick/index';


// Footer fixed bottom
import 'components/FooterFixed/index.js';
import 'components/Event/index.js';

