$(function () {
    console.log('event logic');



    function computePrice($form) {
        console.log('computePrice');
        var price = 0;
        if ($form.find('input[type=radio][name=response]:checked')) {
            var basePrice = parseFloat($form.find('input[name=basePrice]').val());
            price = basePrice;

            //checkOptions
            $('input[type=radio].priced.member:checked').each(function () {
                price = parseFloat($(this).data('price'));
            });

            $('.repeatable').each(function () {

                $(this).find('input[type=radio].priced:checked').each(function () {
                    price += parseFloat($(this).data('price'));
                });


            });
        }
        $form.find('input[type=radio][name=response]').on('change', function () {
            if ($(this).val() == 'yes') {
                $form.find('#show_if_yes').removeClass('d-none');
                $form.find('#show_if_yes [data-required=1]').prop('required', true);
            } else {
                $form.find('#show_if_yes').addClass('d-none');
                $form.find('#show_if_yes [data-required=1]').prop('required', false);
                $('#guests').empty(); // supprimer tous les invités ajoutés
                $form.find('.repeatable').not(':first').remove(); // supprimer toutes les lignes de formulaire d'invité sauf la première
                $form.find('#addGuest').removeClass('d-none'); // afficher le bouton "Ajouter un invité"
                computePrice($form); // recalculer le prix total
            }
        });
        if ($form.find('input[type=radio][name=response]:checked').val() !== 'refused') {
            $form.find('#show_if_yes').removeClass('d-none');
            $form.find('#show_if_yes [data-required=1]').prop('required', true);
        } else {
            $form.find('#show_if_yes').addClass('d-none');
            $form.find('#show_if_yes [data-required=1]').prop('required', false);
            price = 0;
        }
        $('input[name=price]').val(price);
        $('#price_total').text(price);

        if (price <= 0) {
            $('.showIfFree').removeClass('d-none');
            $('.showIfPaid').addClass('d-none');
            $('.showIfFree').find('input').prop('checked',true);
            $('.showIfPaid').find('input').prop('checked',false);
        } else {
            $('.showIfFree').addClass('d-none');
            $('.showIfPaid').removeClass('d-none');
            $('.showIfFree').find('input').prop('checked',false);
            $('.showIfPaid').find('input').prop('checked',true);
        }
        return price;
    }

    if ($('#form_event_subscribe').length > 0) {
        console.log('Binding logic');
        var idxGuest = 0;
        var $form = $('#form_event_subscribe');
        var inviteMax = $form.find('input[name=inviteMax').val();

        var $tplGuest = $('#tplGuest').clone();
        $tplGuest.removeClass('d-none');
        $tplGuest.removeAttr('id');
        $('#tplGuest').remove();
        var wireinstructions = ''
        if ($('#modalWire').length > 0) {
            wireinstructions = $('#modalWire').find('.modal-body').html();
            $('#closeModalWire').on('click', function () {
                $('#modalWire').modal('hide');
            });
            $('#modalWire').on('hide.bs.modal', function () {
                document.location.reload();
            })
        }

        $form.find('input[type=radio][name=response]').on('change', function () {
            if ($(this).val() == 'yes' || $(this).val() !== 'yes') {
                $form.find('#show_if_yes').removeClass('d-none');
                $form.find('#show_if_yes [data-required=1]').prop('required', true);
            } else {
                $form.find('#show_if_yes').addClass('d-none');
                $form.find('#show_if_yes [data-required=1]').prop('required', false);
            }
            computePrice($form);
        });



        $form.on('change', 'input[type=radio].priced', function () {

            computePrice($form);
        });


        $form.find('#addGuest').on('click', function () {

            var $cloned = $tplGuest.clone();
            $cloned.find('.form-check-input').attr('name', 'options_guest_' + idxGuest + '[]');
            $('#guests').append($cloned);
            idxGuest++;
            if (idxGuest >= inviteMax) {
                $form.find('#addGuest').addClass('d-none');
            }
            computePrice($form);


        });

        $form.on('click', '.removeGuest', function () {
            console.log("remove");
            $(this).parent('.repeatable').remove();
            idxGuest = 0;
            $('#guests .repeatable').each(function () {
                $(this).find('.form-check-input').attr('name', 'options_guest_' + idxGuest + '[]');
                idxGuest++;
                if (idxGuest < inviteMax) {
                    $form.find('#addGuest').removeClass('d-none');
                }
            });
            computePrice($form);
        });

        $form.find('input[type=radio][name=paymentMean]').on('change', function () {

        });

        $form.on('submit', function (e) {
            e.preventDefault();

            $('form').addClass('loader');
            $.ajax({
                url: $(this).attr("action"),
                type: 'POST',
                data: $(this).serialize()
            }).done(function (response) { //
                var response = $form.find('input[type=radio][name=response]').val();
                if (response == 'refused') {
                    var paymentMean = $form.find('input[type=radio][name=paymentMean]:checked').val();
                    if (paymentMean === 'wire') {
                        $('#modalWire').find('.modal-body').html(wireinstructions.replace('{price}', computePrice($form)));
                        $('#modalWire').modal('show');
                    } else if (paymentMean === 'stripe') {
                        alert('stripe');
                    }
                } else {
                    document.location.reload();
                }


            });


            console.log('form submitted');
        });
    }
});